<!--
Partners / Testimonials
-->
<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-6 bg-yellow">
        <div class="row">
          <div class="col-lg-4"></div>
          <div class="py-4 col-lg-8 text-center">
            <h1 class="text-blue mb-4" style="text-transform: none">
              Rólunk mondták
            </h1>
            <!--carousel-->
            <div class="container py-4">
              <swiper class="swiper equal-height" :options="swiperOption">
                <swiper-slide
                  v-for="(item, index) in this.testimonials"
                  :key="index"
                >
                  <div class="item">
                    <div class="fa-wrapper">
                      <font-awesome-icon :icon="['fas', 'quote-right']" />
                    </div>
                    <img v-bind:src="item.image" v-bind:alt="item.title" />
                    <div class="carousel-caption text-blue">
                      <div class="fst-italic" v-html="item.text" />
                      {{ item.title }}
                    </div>
                  </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div>
          </div>
        </div>
      </div>
      <!--carousel-->

      <div class="col-lg-6 bg-white">
        <!--Partnereink-->
        <div class="px-5">
          <div class="row">
            <div class="py-4 col-lg-8 text-center">
              <h1 class="text-blue mb-5 text-capitalize">Partnereink</h1>
              <swiper class="swiper equal-height" :options="swiperOption">
                <swiper-slide
                  v-for="(items, index) in this.chunkedItems"
                  :key="index"
                >
                  <ul
                    class="nav col-12 col-lg-auto me-lg-auto mb-2 d-flex justify-content-center align-items-center mb-md-0"
                  >
                    <li
                      v-for="(item, index) in items"
                      :key="index"
                      class="list-inline-item"
                    >
                      <a :href="item.url" class="p-1" target="_blank">
                        <img
                          v-bind:src="item.image"
                          v-bind:alt="item.title"
                          class="partner-logo"
                      /></a>
                      <div class="clearfix"></div>
                    </li>
                  </ul>
                </swiper-slide>
                <div
                  class="swiper-pagination partner-pagination"
                  slot="pagination"
                ></div>
              </swiper>
            </div>
            <!--Partnereink-->
            <div class="col-lg-4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import _ from "lodash";

export default {
  name: "module4",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: { data: [Array] },
  },
  data() {
    return {
      testimonials: this.data.testimonials,
      partners: this.data.partners,
      swiperOption: {
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        keyboard: {
          enabled: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        speed: 700,
      },
      swiperHeight: null,
    };
  },
  computed: {
    chunkedItems() {
      return _.chunk(this.partners, 6);
    },
  },
};
</script>

<style scoped></style>
